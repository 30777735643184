<template>
    <v-card hover>
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">통화불가 시간</v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="pb-0 pl-7 pr-7 pt-3">
            <v-row>
                <v-col>
                    <span class="title">시작</span>
                    <v-select
                        :items="startSels"
                        v-model="start_time"
                        item-text="txt"
                        item-value="val"
                        color="primary"
                        hide-details
                        style="font-size:20px;"
                    >
                    </v-select>
                </v-col>
                <v-col style="border-left:1px solid #eeeeee;">
                    <span class="title">종료</span>
                    <v-select
                        :items="endSels"
                        v-model="end_time"
                        item-text="txt"
                        item-value="val"
                        color="primary"
                        hide-details
                        style="font-size:20px;"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="">
            <v-row class="mt-3">
                <v-col cols="12" class="pr-5 pb-6 pt-0 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" text v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
export default {
    props: ['breakTime'],
    data: () => ({
        startSels: [],
        endSels: [],
        start_time: '1200',
        end_time: '1300'
    }),
    created() {
        for (let h = 0; h < 24; h++) {
            for (let m = 0; m < 60; m += 5) {
                this.startSels.push({
                    txt: (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m,
                    val: (h < 10 ? '0' : '') + h + (m < 10 ? '0' : '') + m,
                })
                this.endSels.push({
                    txt: (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m,
                    val: (h < 10 ? '0' : '') + h + (m < 10 ? '0' : '') + m,
                })
            }
        }
        this.startSels.push({txt: '24:00', val: '2400'})
        this.endSels.push({txt: '24:00', val: '2400'})
    },
    mounted() {
        this.start_time = this.breakTime.break_start + ''
        this.end_time = this.breakTime.break_end + ''
    },
    methods: {
        closePopup() {
            this.$emit('cancel')
        },
        submitPopup() {
            let startTime = this.start_time
            let endTime = this.end_time

            if (parseInt(startTime) >= parseInt(endTime)) {
                this.$store.dispatch('openAlert', {message: '시작시간은 종료시간과 같거나 클 수 없습니다.'})
                return
            }

            this.$emit('submit', {'idx': this.breakTime.idx, 'break_start': this.start_time, 'break_end': this.end_time})
        },
    }
}
</script>